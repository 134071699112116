<i18n lang="json">
{
  "en": {
    "prevPhoto": "Previous photo",
    "nextPhoto": "Next photo",
    "photoIndex": "Go to photo #{0}"
  },
  "fr": {
    "prevPhoto": "Photo précédente",
    "nextPhoto": "Photo suivante",
    "photoIndex": "Aller à la photo n°{0}"
  }
}
</i18n>

<template>
  <div v-if="images.length" ref="glideEl" class="glide bg-white">
    <div class="glide__track" data-glide-el="track">
      <ul class="glide__slides" @touchstart.passive.once="initGlide()">
        <li v-for="(image, imageIndex) in resizedImages" :key="image" class="glide__slide">
          <img
            :src="image"
            alt=""
            width="400"
            height="267"
            class="image"
            :loading="imageIndex === 0 && eagerLoading ? 'eager' : 'lazy'"
            :fetchpriority="imageIndex === 0 && highPriorityFirstImage && eagerLoading ? 'high' : 'low'"
            @click="(e) => handleImageClick(e, images[imageIndex]!)"
          />
        </li>
      </ul>
    </div>

    <div v-if="showNavigationArrows && images.length > 1" class="glide__arrows" data-glide-el="controls">
      <button
        class="glide__arrow glide__arrow--left"
        data-glide-dir="<"
        @click.once="initGlide(images.length - 1)"
        :aria-label="t('prevPhoto')"
      >
        <IconChevron direction="left" />
      </button>
      <button
        class="glide__arrow glide__arrow--right"
        data-glide-dir=">"
        @click.once="initGlide(1)"
        :aria-label="t('nextPhoto')"
      >
        <IconChevron direction="right" />
      </button>
    </div>

    <div v-if="images.length > 1" class="glide__bullets" data-glide-el="controls[nav]">
      <button
        v-for="(_, index) in images"
        :key="index"
        :class="['glide__bullet', { 'glide__bullet--active': index === 0 }]"
        :data-glide-dir="`=${index}`"
        @click.once="initGlide(index)"
        :aria-label="t('photoIndex', index + 1)"
      />
    </div>
  </div>
  <div v-else class="image"></div>
</template>

<script lang="ts" setup>
import Glide, { Controls, Swipe } from '@glidejs/glide/dist/glide.modular.esm'
import { useElementVisibility, useResizeObserver, watchOnce } from '@vueuse/core'

const props = withDefaults(
  defineProps<{
    href: string
    target?: string
    images: string[]
    itemId: string
    eagerLoading?: boolean
    showNavigationArrows?: boolean
    highPriorityFirstImage?: boolean
  }>(),
  {
    showNavigationArrows: true
  }
)

const { t } = useI18n()

const emit = defineEmits<{
  click: [value: string]
}>()

const handleImageClick = (e: MouseEvent, image: string) => {
  emit('click', image)
}

const initGlide = (slideIndex?: number) => {
  if (glide.value) return

  glide.value = new Glide(glideEl.value!, { type: 'carousel' }).mount({ Swipe, Controls })
  glide.value.on('run', () => {
    if (glide.value == null) return
    const image = props.images.at(glide.value?.index)!

    segmentTrack('Product Image Changed', {
      display_type: 'carousel',
      image_count: props.images.length,
      image_id: image,
      position: glide.value?.index + 1,
      product_id: props.itemId,
      url: image
    })
  })
  if (slideIndex != null) glide.value.go(`=${slideIndex}`)
}

const isLargeScreen = useIsLargeScreen()

const glide = ref<Glide>()
const glideEl = ref<HTMLDivElement>()

const targetIsVisible = useElementVisibility(glideEl)

const resizedImages = computed(() =>
  props.images.map(
    (image) => `${image}?width=${isLargeScreen.value ? 300 : 400}&height=${isLargeScreen.value ? 200 : 267}`
  )
)

onMounted(() => {
  useResizeObserver(glideEl.value, () => {
    glide.value?.update()
  })
})

onBeforeUnmount(() => {
  glide.value?.destroy()
})

watchOnce(targetIsVisible, () => {
  initGlide()
})

useHead({
  link: [
    props.highPriorityFirstImage
      ? {
          rel: 'preload',
          as: 'image',
          fetchpriority: 'high',
          href: resizedImages.value.at(0)
        }
      : {}
  ]
})
</script>

<style lang="scss" scoped>
@import 'node_modules/@glidejs/glide/src/assets/sass/glide.core';

.image {
  width: 100%;
  aspect-ratio: 3/2;
  object-fit: cover;
  vertical-align: bottom;
  background: #d9dcdf; // gray-100
  border-radius: 1rem;
}

.glide {
  &__arrow {
    position: absolute;
    top: 50%;
    z-index: 2;
    transform: translateY(-50%);
    opacity: 0;
    background-color: rgba(255, 255, 255, 0.8);
    color: blue;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border: 0;
    cursor: pointer;
    transition: 0.4s;

    display: flex;
    align-items: center;
    justify-content: center;

    &:focus {
      outline: none;
    }

    svg {
      width: 1rem;
      height: 1rem;
      vertical-align: middle;
    }

    &--left {
      left: 10%;
    }

    &--right {
      right: 10%;
    }

    &--disabled {
      opacity: 0.33;
    }
  }

  &__bullets {
    position: absolute;
    z-index: 2;
    left: 50%;
    display: inline-flex;
    list-style: none;
    transform: translateX(-50%);

    --bullet-size: 0.5rem;
    margin: 0;
    width: calc((var(--bullet-size) * 5) + (var(--bullet-size) * 4));
    height: var(--bullet-size);
    bottom: 0.75rem;
  }

  &__bullet {
    height: var(--bullet-size);
    width: var(--bullet-size);
    display: none;
    transform: scale(0);
    position: absolute;
    margin: 0;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 0;
    border-radius: 50%;
    border: 0.125rem solid transparent;
    transition: all 300ms ease-in-out;
    cursor: pointer;
    line-height: 0;
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);

    &:focus {
      outline: none;
    }

    &--active {
      background-color: white;
      transform: scale(1);
      opacity: 1;
      display: block;
      left: calc(50% - (var(--bullet-size) / 2));
    }
  }

  &__bullet:has(+ .glide__bullet--active),
  &__bullet--active + &__bullet,
  &__bullet:has(+ .glide__bullet + .glide__bullet--active),
  &__bullet--active + &__bullet + &__bullet {
    display: block;
  }

  &__bullet:has(+ .glide__bullet--active),
  &__bullet--active + &__bullet {
    transform: scale(0.66);
  }

  &__bullet:has(+ .glide__bullet + .glide__bullet--active),
  &__bullet--active + &__bullet + &__bullet {
    transform: scale(0.33);
  }

  &__bullet:has(+ .glide__bullet + .glide__bullet--active) {
    left: calc(0% - (var(--bullet-size) / 2));
  }

  &__bullet:has(+ .glide__bullet--active) {
    left: calc(25% - (var(--bullet-size) / 2));
  }

  &__bullet--active + &__bullet {
    left: calc(75% - (var(--bullet-size) / 2));
  }

  &__bullet--active + &__bullet + &__bullet {
    left: calc(100% - (var(--bullet-size) / 2));
  }

  &:hover {
    .glide__arrow {
      opacity: 1;

      &--left {
        left: 2%;
      }

      &--right {
        right: 2%;
      }
    }
  }
}
</style>
